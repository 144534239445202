import {Directive, HostListener} from '@angular/core';

@Directive({
	selector: '[stopClickEventPropagation]'
})
export class StopClickEventPropagationDirective {
	@HostListener('click', ['$event'])
	stopPropagation(e: Event): void {
		e.stopImmediatePropagation();
		e.stopPropagation();
	}
}
